.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.modal-content {
    background: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: auto;
    max-width: 400px; /* Max width for the modal */
    text-align: center;
    z-index: 1001;
}

.modal-content button {
    background-color: #007bff;
    border: none;
    border-radius: 5px;
    color: white;
    padding: 10px 20px;
    margin: 10px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.modal-content .button-group button {
    background-color: #007bff;
    border: none;
    border-radius: 5px;
    color: white;
    padding: 10px 20px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    width: 100px; /* Set a fixed width */
    height: 40px; /* Set a fixed height */
    margin: 0 10px; /* Spacing between buttons */
}

.modal-content .button-group button:hover {
    opacity: 0.2;
}

/* Specific style for the Cancel button */
.modal-content .button-group button.cancel-button {
    background-color: #dc3545; /* Red color for Cancel button */
}

.button-group {
    padding-top: 10px;
}

.modal-content .button-group button.cancel-button:hover {
    background-color: #c82333; /* Darker red on hover */
}

